.drawer-toolbar {
  background-color: #111726;
}

.fitness-name-logo {
  margin: auto;
  font-weight: bold;
  font-size: 18px;
  line-height: 16px;
  text-transform: uppercase;
}