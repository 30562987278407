.oval {
  z-Index: 100;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.oval-default {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30vh;
}

.loader-no-margin {
  margin: 0;
  padding: 5px;
  width: 270px;
}

.oval-chat {
  margin: 30vh auto;
}

.loader-training-weight-table {
  margin: 0;
}

.chat-loader {
  margin: auto;
  max-width: 40px;
}

@media (max-width: 768px) {
  .oval-default {
    margin: 30vh 0;
  }
}