.add-settings-card-container {
  display: flex;
  flex-direction: column;
  width: 66%;
  margin: auto;
  margin-top: 50px;

  .profile-image-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin: 0 auto 40px auto;

    img {
      border-radius: 50%;
      object-fit: cover;
      width: 50px;
      height: 50px;
    }
  }

  .link {
    display: flex;
    align-self: flex-end;
    font-size: 16px;
    margin-left: 20px;
    color: #5048E5;
    font-weight: 600
  }

  .link:hover {
    cursor: pointer;
  }

  .header-container {
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  .table-tab-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: white;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;

    .table-tab-active,
    .table-tab-inactive {
      padding: 10px 25px 10px;
      border-bottom: 3px black solid;
      font-size: 18px;
      margin-left: 15px;
    }

    .table-tab-active:hover,
    .table-tab-inactive:hover {
      cursor: pointer;
    }

    .table-tab-inactive {
      border-bottom: 3px white solid;
    }
  }

  .button-container {
    padding: 20px 0 20px;
    display: flex;
    align-self: flex-end;

    .button-primary {
      background-color: #5048E5;
      color: white;
      font-weight: 700;
      padding: 10px 15px 10px;
    }
  }

  .add-settings-name {
    display: flex;
    align-self: flex-start;
    font-size: 20px;
    padding-bottom: 5px;
    margin-left: 10px;
  }

  .add-settings-subtitle {
    font-size: 16px;
    padding-left: 20px;
  }

  .card-container {
    display: flex;
    background-color: white;
    border-radius: 15px;
    flex-direction: row;
    box-shadow: 1px 3px 5px #00000020;
    width: 100%;
    justify-content: space-between;
    padding: 20px;
    height: 30%;

    .form-container {
      display: flex;
      flex-direction: column;
      padding: 20px;

      .form-text-input,
      .form-text-input-small {
        margin-bottom: 20px;
        border-radius: 15px;
        border-color: rgba(0, 0, 0, 0.2);
        width: 30vw;
      }

      .form-text-input-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        max-width: 30vw;
      }

      .form-text-input-small {
        width: 14vw;
      }
    }
  }

  .card-container:nth-child(3) {
    margin-top: 20px;
  }

  .small-form-container {
    display: flex;
    background-color: white;
    border-radius: 15px;
    margin-top: 20px;
    flex-direction: row;
    box-shadow: 1px 3px 5px #00000020;
    width: 100%;
    justify-content: space-between;
    padding: 20px;
    height: 30%;

    .weight-text-fields-container {
      display: flex;
      flex-direction: row;
      padding: 20px;

      .weight-text-field {
        margin: 0 10px 20px;
        border-radius: 15px;
        border-color: rgba(0, 0, 0, 0.2);
        width: 10vw;
      }
    }
  }
}

@media (max-width: 768px) {
  .add-settings-card-container {
    width: 100%;
    margin-top: 0;
    padding: 0 10px;

    .button-container {
      width: 100%;
      justify-content: center;

      .button-primary {
        width: 100%;
      }
    }

    .card-container {
      padding: 0;
    }
  }
  .add-settings-card-container .card-container {
    flex-direction: column;


    .form-container {
      .form-text-input,
      .form-text-input-small {
        width: unset;

      }

      .profile-image-container {
        flex-direction: column;
        margin: auto;
        align-items: center;

        img {
          width: 130px;
          height: 130px;
        }
      }
    }


  }

}
