.table-img {
  max-width: 7vw;
}

.table-cell-header {
  font-size: 14px;
}

.table-cell-p {
  color: #71859C;
  max-width: 25vw;
}