.draggable-container {
  flex-direction: row;
  padding: 0 20px;
}

.draggable-component-container {
  background-color: #71859C25;
  border-radius: 20px;
  padding: 0.5vw 2vw 0.5vw 2vw;
  align-items: center;
  cursor: move;
}

.recipe-draggable-container {
  flex-direction: row;
}

.draggable-input-container {
  position: relative;

  img {
    position: absolute;
    right: 7px;
    top: 10px;
    width: 35px;
    z-index: 10;

  }

  img:hover {
    cursor: pointer;
  }
}

.autocomplete-wrapper {
  background-color: #71859C25;
  border-radius: 15px;
  margin-bottom: 15px;
  padding: 1px 5px 10px 5px;

  .autocomplete-wrapper-detail {
    display: flex;
    flex-direction: row;
    gap: 5px;
  }
}

@media (max-width: 768px) {
  .draggable-container {
    padding: 0 10px;
  }
}
