.table-row:hover {
  cursor: pointer;
}

.table-container {
  margin-top: 20px;
  width: 100%;

  .table-wrapper {
    max-height: 400px;
    overflow: auto
  }

  .table {
    height: 100px;
    overflow-y: auto;
    background-color: white;
  }
  
  .table-cell-header {
    color: #71859C;
    font-weight: 700;
  }

  .table-cell-data,
  .table-cell-data-comment {
    font-weight: 700;
    font-size: 12px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .table-cell-data-comment {
    max-width: 15vw;
  }
}

.client-card-container {
  display: flex;
  flex-direction: column;
  width: 66%;
  margin: auto;
  margin-top: 50px;

  .header-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    .page-name-container {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .open-chat-button {
      background-color: #00BB83;
      color: white;
      font-weight: bold;
      padding: 6px 12px;
      border-radius: 5px;
    }
  }

  .table-tab-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: white;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;

    .table-tab-active,
    .table-tab-inactive {
      padding: 10px 25px 10px;
      border-bottom: 3px black solid;
      font-size: 18px;
      margin-left: 15px;
    }

    .table-tab-active:hover,
    .table-tab-inactive:hover {
      cursor: pointer;
    }

    .table-tab-inactive {
      border-bottom: 3px white solid;
    }
  }

  .button-container {
    padding: 20px 0 20px;
    display: flex;
    align-self: flex-end;

    .button-primary {
      background-color: #5048E5;
      color: white;
      font-weight: 700;
      padding: 10px 15px 10px;
    }

    .button-with-stroke {
      background: none;
      border: 2px solid #5048E5;
      color: #5048E5;
      margin: 0 15px 0;
      font-weight: 700;
      padding: 10px 15px 10px;
    }
  }

  .client-name {
    display: flex;
    align-self: flex-start;
    font-size: 20px;
    padding-bottom: 5px;
    margin-left: 10px;
  }

  .client-subtitle {
    font-size: 16px;
    padding-left: 20px;
  }

  .card-container {
    display: flex;
    background-color: white;
    border-radius: 15px;
    flex-direction: row;
    box-shadow: 1px 3px 5px #00000020;
    width: 100%;
    justify-content: space-between;
    padding: 20px;
    height: 30%;

    .packages-container {
      display: flex;
      flex-direction: column;

      h4 {
        margin: 5px 0
      }
    }

    .form-container {
      display: flex;
      flex-direction: column;
      padding: 20px;

      .form-container-row {
        display: flex;
        justify-content: space-between;
        width: 32vw;
      }

      .form-text-input,
      .form-text-input-medium,
      .form-text-input-small {
        margin-bottom: 20px;
        border-radius: 15px;
        border-color: rgba(0, 0, 0, 0.2);
        width: 30vw;
      }

      .form-text-input-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        max-width: 30vw;
      }

      .package-prices-container {
        display: flex;
        flex-direction: column;

        h4 {
          margin-top: 0;
        }
      }

      .form-text-input-medium {
        width: 15vw;
      }

      .full-name-form-text-input {
        width: 15vw;
      }

      .form-text-input-small {
        width: 10vw;
      }

      .form-height-text-input {
        width: 7vw;
      }
    }
  }

  .small-form-container {
    display: flex;
    background-color: white;
    border-radius: 15px;
    margin-top: 20px;
    flex-direction: row;
    box-shadow: 1px 3px 5px #00000020;
    width: 100%;
    justify-content: space-between;
    padding: 20px;
    height: 30%;

    .weight-text-fields-container {
      display: flex;
      flex-direction: row;
      padding: 20px 20px 20px 10px;
      justify-content: space-between;
      width: 34vw;

      .weight-text-field {
        margin: 0 0 20px;
        border-radius: 15px;
        border-color: rgba(0, 0, 0, 0.2);
        width: 9vw;
      }
    }

    .file-uploader-container {
      padding: 0 20px;
    }
  }

  .cover-image {
    width: 100%;
    object-fit: contain;
    display: flex;
    align-self: center;
  }

}

.item-table-wrapper, {
  padding: 30px;

  .page-header-with-table-container {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  h1.client-table-header {
    font-size: 24px;
  }

  .search-wrapper {
    position: relative;

    .search-icon {
      position: absolute;
      left: 20px;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
    }

    .search-input {
      padding: 15px 20px 15px 50px;
      width: 100%;
      font-size: 14px;
      border-width: 1px;
      border-color: rgba(0, 0, 0, 0.1);
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }

  .item-table-row {
    background-color: #71859C25;
  }

  .item-table-cell-header {
    color: #71859C;
    font-weight: 700;
  }

  .item-table-cell {
    font-weight: 700;
    font-size: 12px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

@media(max-width: 768px) {
  .item-table-wrapper, {
    padding: 10px;

    h1.client-table-header {
      font-size: 18px;
    }
  }
  .client-card-container {
    width: 100%;
    padding: 0 10px;
    margin-top: 5px;

    .header-container {
      .open-chat-button {
        font-size: 12px;
        margin: auto 0;
        white-space: nowrap;
      }

    }

    .card-container {
      flex-direction: column;
      justify-content: flex-start;
      height: unset;

      .client-subtitle {
        padding: 0;
      }

      .form-container {
        padding: 10px 0;

        .form-text-input {
          width: 100%;
        }

        .form-text-input-wrapper {
          flex-direction: column;
          max-width: 100%;

          .form-text-input-small {
            width: 100%;
          }
        }

        .form-container-row {
          width: 100%;
          flex-direction: column;
          justify-content: space-between;

          .full-name-form-text-input {
            width: 100%;
            padding-bottom: 15px;
          }

          .form-text-input-medium {
            width: 100%;
          }

          .form-text-input-small {
            width: 100%;
          }

          .form-height-text-input {
            width: 100%;
          }

        }
      }
    }

    .small-form-container {
      flex-direction: column;

      .client-subtitle {
        padding: 0;
      }

      .weight-text-fields-container {
        flex-direction: column;
        width: 100%;
        padding: 0;

        .weight-text-field {
          width: 100%;
        }
      }

      .file-uploader-container {
        label {
          min-width: unset;
        }
      }
    }

    .table-container {
      overflow-x: auto;

      .small-table-container {
        overflow-x: auto;
      }

      .table-tab-container {
        overflow-x: auto;

        .table-tab-active,
        .table-tab-inactive {
          padding: 0 10px;
          font-size: 15px;

        }
      }
    }

    .button-container {
      flex-direction: column;
      align-items: center;
      width: 100%;
      justify-content: space-between;

      .button-with-stroke {
        margin-bottom: 10px;
      }

      .button-primary {
        font-size: 14px;
      }
    }

    .button-container button {
      width: 100%;
    }
  }
}
