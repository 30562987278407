.add-food-card-container {
  display: flex;
  flex-direction: column;
  width: 66%;
  margin: auto;
  margin-top: 50px;


  .header-container {
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  .table-tab-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: white;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;

    .table-tab-active,
    .table-tab-inactive {
      padding: 10px 25px 10px;
      border-bottom: 3px black solid;
      font-size: 18px;
      margin-left: 15px;
    }

    .table-tab-active:hover,
    .table-tab-inactive:hover {
      cursor: pointer;
    }

    .table-tab-inactive {
      border-bottom: 3px white solid;
    }
  }

  .button-container {
    padding: 20px 0 20px;
    display: flex;
    align-self: flex-end;

    .button-primary {
      background-color: #5048E5;
      color: white;
      font-weight: 700;
      padding: 10px 15px 10px;
    }
  }

  .add-food-name {
    display: flex;
    align-self: flex-start;
    font-size: 20px;
    padding-bottom: 5px;
    margin-left: 10px;
  }

  .add-food-subtitle {
    font-size: 16px;
    padding-left: 20px;
  }

  .card-container {
    display: flex;
    background-color: white;
    border-radius: 15px;
    flex-direction: row;
    box-shadow: 1px 3px 5px #00000020;
    width: 100%;
    justify-content: space-between;
    padding: 20px;
    height: 30%;

    .form-container {
      display: flex;
      flex-direction: column;
      padding: 20px;

      .form-text-input,
      .form-text-input-small {
        margin-bottom: 20px;
        border-radius: 15px;
        border-color: rgba(0, 0, 0, 0.2);
        width: 30vw;
      }

      .form-text-input-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        max-width: 30vw;
      }

      .form-text-input-small {
        width: 14vw;
      }
    }
  }

  .small-form-container {
    display: flex;
    background-color: white;
    border-radius: 15px;
    margin-top: 20px;
    flex-direction: row;
    box-shadow: 1px 3px 5px #00000020;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    height: 30%;

    .small-form-buttons {
      display: flex;

      .button-container {
        padding: 0px;
      }
    }

    .file-uploader-container {
      padding: 0 20px;
    }

    .recipe-form-container {
      padding: 20px;

      .recipe-draggable-wrapper {
        margin-bottom: 20px;
      }
    }

    .weight-text-fields-container {
      display: flex;
      flex-direction: row;
      padding: 20px;

      .weight-text-field {
        margin: 0 10px 20px;
        border-radius: 15px;
        border-color: rgba(0, 0, 0, 0.2);
        width: 10vw;
      }
    }
  }
}

@media (max-width: 768px) {
  .add-food-card-container {
    width: 100%;
    margin-top: 0;
    padding: 0 10px;

    .button-container {
      width: 100%;
      justify-content: center;

      .button-primary {
        width: 100%;
      }
    }

    .add-food-subtitle {
      padding-left: 15px;
    }

    .card-container {
      flex-direction: column;
      padding: 0;

      .form-container {
        .form-text-input,
        .form-text-input-small {
          width: unset;
        }
      }
    }

    .small-form-container {
      flex-direction: column;
      padding: 0 15px 15px 15px;
      margin-bottom: 10px;
      align-items: inherit;

      .file-uploader-container {
        display: flex;
        flex-direction: column;
        //width: 100%;
        padding: 0 10px;

        label {
          min-width: unset;
        }
      }
    }
  }
}
