.videoWrapper, .selected {
  max-width: 25%;
  padding: 10px;
  line-height: 0;
  border-radius: 10px;

  video {
    border: 4px solid transparent;
    width: 100%;
  }

  h5 {
    margin: 10px 0 20px 4px;
    line-height: 20px;
    text-align: center;
  }
}

.videoContainer {
  display: flex;
  margin: 0 -10px 0 -10px;
  overflow: auto;
  height: 50vh;
  justify-content: center;

  .videos {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 90%;
  }
}

.small-form-container {
  .button-container.select-video-btn {
    padding-top: 0px;
  }

  .small-form-buttons {
    display: flex;
  }
}

.selected {
  video {
    border: 4px solid #5048E5;
    border-radius: 10px;
  }
}

@media (max-width: 768px) {

  .videoContainer {
    height: 60vh;

    .videos {
      justify-content: center;
    }
  }

  .videoContainer .videoWrapper, .videoContainer .selected {
    max-width: 50%;
  }
}
