.notification,
.mobileNotification {
  position: relative;
  left: 0;
  padding: 20px 25px 0 20px;
  font-size: 15px;
  color: #5F7093;
  font-weight: 700;
  cursor: pointer;

  .is-read {
    width: 10px;
    height: 10px;
    border-radius: 50px;
    background-color: #1976d2;
  }

  .notification-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    img {
      width: 35px;
      height: 35px;
      border-radius: 50px;
    }
  }
}

.mobileNotification {
  padding: 2vw 2vw 0vw 2vw;
}

.notification h1,
.mobileNotification h1 {
  font-size: 16px;
  font-weight: 700;
  color: #1D1D1D;
  margin: 0;
}

.mobileNotification h1 {
  font-size: 14px;
}

.notification .row img,
.mobileNotification .row img {
  border-radius: 50%;
}

.mobileNotification .row img:first-child {
  width: 45px;
  height: 45px;
}

.notification h2,
.mobileNotification h2 {
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}

.mobileNotification h2 {
  font-size: 15px;
}

.notification p,
.mobileNotification p {
  font-weight: 300;
  font-size: 16px;
  margin: 0;
}

.mobileNotification p {
  font-size: 12px;
}

.notification .row,
.mobileNotification .row {
  display: flex;
  flex-direction: row;
  align-items: center;

}

.mobileNotification .row {
  justify-content: space-between;
  padding: 0 3vw;
}

.notification .row .column,
.mobileNotification .row .column {
  display: flex;
  flex-direction: column;
  padding: 0vw 15px;
  width: 280px;

  .notification-text {
    max-width: 20vw;
    word-break: break-word;
  }
}

.mobileNotification .row .column {
  padding: 2vw 3vw;
  width: unset;
}

.notification .borderBottom,
.mobileNotification .borderBottom {
  position: relative;
  border-bottom: 1px rgba(95, 112, 147, 0.2) solid;
  width: 350px;
  padding: 7px 0;
}

.mobileNotification .borderBottom {
  width: 240px;
}

@media (max-width: 768px) {
  .notification {
    .borderBottom {
      width: 235px;
    }

    .row {
      .notification-container {
        max-width: 210px;
      }
    }

    .row .column .notification-text, .mobileNotification .row .column .notification-text {
      max-width: inherit;
    }
  }
  .notification-container {
    max-width: 250px;
  }
}
