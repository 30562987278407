.imageContainer {
  display: flex;
  margin: 0 -10px 0 -10px;
  overflow: auto;
  height: 50vh;
  justify-content: center;

  .images {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 90%;

    img {
      width: 250px;
      height: 180px;
      border-radius: 10px;
      object-fit: cover;
    }
  }
}

.imageWrapper, .selected {
  max-width: 25%;
  padding: 10px;
  line-height: 0;
  border-radius: 10px;

  img {
    border: 4px solid transparent;
  }
}

.selected {
  img {
    border: 4px solid #5048E5;
    border-radius: 10px;
  }
}

.modal-button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
  padding-top: 15px;

  button {
    background-color: #5048E5;
    color: white;
    font-weight: 700;
    padding: 10px 15px 10px;
    border-radius: 10px;
    border: none;
    text-transform: uppercase;
    line-height: 1.75;
    cursor: pointer;
  }
}

@media (min-width: 1600px) {

  .message-input-container {
    height: 15vh;
  }

}

@media (max-width: 768px) {

  .imageContainer {
    height: 60vh;

    .images {
      justify-content: center;

      img {
        width: 300px;
        height: 190px;
      }
    }
  }

  .imageContainer .imageWrapper, .imageContainer .selected {
    max-width: 100%;
  }

  .modal-button-container {
    padding: 15px 20px 0 20px;
  }

  .message-input-container {
    margin-top: 10px;
  }

  .imageWrapper img, .selected img {
    height: auto;
  }

  button {
    font-size: 10px;
  }

}
