.input-fields-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media(max-width: 768px) {
  .input-fields-row {
    flex-direction: column;
  }
}
