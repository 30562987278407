.back-arrow {
  transform: scaleX(-1);

  img {
    width: 18px;
  }

  img:hover {
    cursor: pointer;
  }
}