.pay-slip-text-input-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.pay-slip-date {
  align-self: flex-end;
  font-size: 12px;
  color: #71859C;
  margin: 0;
}

.pay-slip-text-input,
.pay-slip-text-input-medium,
.pay-slip-text-input-small,
.pay-slip-text-input-large {
  margin-bottom: 20px !important;
  border-radius: 15px;
  border-color: rgba(0, 0, 0, 0.2) !important;
  width: 30vw;
}

.pay-slip-text-input-medium {
  max-width: 14.5vw;
}

.pay-slip-text-input-small {
  max-width: 9vw;
}

.pay-slip-text-input-large {
  max-width: 16vw;
}

@media(max-width: 768px) {
  .pay-slip-text-input-large {
    max-width: 100%;
    width: unset;
  }
  .pay-slip-input-fields-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    .pay-slip-text-input-medium {
      max-width: 100%;
      width: 100%;
    }
  }
  .pay-slip-text-input-wrapper {
    .pay-slip-text-input-small {
      max-width: 40%;
      width: 33%;
    }

    .pay-slip-text-input-small:nth-child(2) {
      width: 22%;
    }

    .pay-slip-text-input-small:nth-child(3) {
      width: 40%;
    }

    .pay-slip-text-input-medium {
      max-width: 45%;
      width: 45%;
    }
  }
  .pay-slip-text-input {
    width: 100%;
  }
}
