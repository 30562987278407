.chat-container {
  display: flex;
  flex-direction: row;
  background: white;
  /* Expand container to full height while subtracting the height of header */
  height: calc(100% - 64px);

  .chat-container-fixed {
    flex: 2;

    .user-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .chat-image-wrapper {
      display: flex;
      position: absolute;
      right: 110px;
      bottom: 90px;
      background: white;
      width: 20vw;
      height: 200px;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      align-items: center;
      justify-content: center;

      img.close-icon {
        position: absolute;
        top: 0;
        right: 15px;
        width: 15px;
        object-fit: contain;
      }

      img.main-image {
        width: 16.5vw;
        height: auto;
        border-radius: unset;
        max-height: 160px;
        object-fit: contain;
      }
    }
  }

  .all-chats-container {
    flex: 0.5;
    padding-left: 20px;
    padding-right: 20px;

    .header {
      font-size: 24px;
    }

    .all-chats-wrapper {
      overflow: auto;
      max-height: 85%;
      margin-top: 10px;
    }
  }

  .border {
    width: 1px;
    background: #11172620;
  }

  .user {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 15px;
    padding-bottom: 15px;
    padding-left: 20px;

    img {
      width: 50px;
      object-fit: cover;
      height: 50px;
      border-radius: 50px;
    }

    h2 {
      margin-left: 15px;
      font-size: 16px;
    }
  }
}


.button-container,
.button-container-modal {
  padding: 20px 30px;
  display: flex;
  align-self: flex-end;

  .button-primary {
    background-color: #5048E5;
    color: white;
    font-weight: 700;
    padding: 10px 15px 10px;
    border-radius: 10px;

    &:hover {
      background-color: #5048E5;
    }

    &:disabled {
      color: white;
    }
  }

  .button-with-stroke {
    background: none;
    border: 2px solid #5048E5;
    color: #5048E5;
    margin: 0 15px 0;
    font-weight: 700;
    padding: 10px 15px 10px;
    border-radius: 10px;
  }
}

.button-container-modal {
  padding: 20px 0 0;
}

.modal-box {
  position: absolute;
  top: 30%;
  left: 30%;
  background-color: white;
  width: 50vw;
  display: flex;
  justify-content: space-around;
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  padding: 30px 20px;

  .form-container {
    width: 30vw;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .date-and-time-container {
      margin-bottom: 20px;
      padding-top: 20px;
    }

    .form-text-input,
    .form-text-input-small {
      margin-bottom: 20px;
      border-radius: 15px;
      border-color: rgba(0, 0, 0, 0.2);
      width: 25vw;
    }

    .form-text-input,
    .form-text-input-small:first-child {
      margin-right: 20px;
    }

    .form-text-input-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      max-width: 30vw;
    }

    .form-text-input-small {
      width: 12vw;
    }

    .text-area {
      min-height: 150px;
      padding: 15px;
      width: 25vw;
      margin-bottom: 20px;
      border-radius: 10px;
      border-color: rgba(0, 0, 0, 0.2);
    }
  }
}

.messages-container {
  background: #11172625;
  max-height: 70vh;
  padding: 20px;
  overflow: auto;
  height: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column-reverse;
}

.infinite-scroll {
  justify-content: flex-start;
  display: flex;
  flex-direction: column-reverse;
}

.chat-item-container-active,
.chat-item-container-inactive {
  display: flex;
  flex-direction: row;
  background: white;
  align-items: center;
  padding: 10px 20px 10px 20px;
  border-bottom: 1px solid #71859c50;
  justify-content: space-between;

  .user-image {
    display: flex;
    align-items: center;

    img {
      width: 50px;
      object-fit: cover;
      height: 50px;
      border-radius: 50px;
    }

    h2 {
      margin-left: 15px;
      font-size: 16px;
      font-weight: 500;
    }

    .unread-message-indicator {
      background: #D32F2F;
      border-radius: 50%;
      width: 12px;
      height: 12px;
    }
  }
}

.chat-item-container-active,
.chat-item-container-inactive:hover {
  cursor: pointer;
}

.chat-item-container-inactive {
  background: #71859c35;
}

.message-input-container {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  align-items: center;

  > img {
    width: 40px;
    height: 40px;
    border-radius: 50px;
    object-fit: cover;
  }

  label input {
    display: none;
  }

  .message-text-input {
    margin: 0 10px;
    border-color: rgba(0, 0, 0, 0.2);
    width: 30vw;
    overflow: auto;
    flex: 8;
  }

  .icons-wrapper {
    flex: 0.5;
    display: flex;

    img {
      width: auto;
      height: auto;
      border-radius: unset;
      object-fit: unset;
    }

    img:nth-child(2) {
      padding-top: 5px;
    }

    img:hover {
      cursor: pointer;
    }

    .icon-with-margin {
      margin: 0 10px 0 10px;
    }
  }
}

@media (max-width: 768px) {

  .chat-container {
    flex-direction: column;
    overflow-y: hidden;

    .chat-container-fixed {
      overflow-y: auto;

      .chat-image-wrapper {
        width: 200px;
        height: 190px;
        bottom: 98px;
        right: auto;

        img.main-image {
          width: 100%;
        }
      }
    }

    .all-chats-container {
      padding: 0 10px;

      .all-chats-wrapper {
        max-height: 80vh;
      }
    }

    .user-container {
      align-items: center;

      .user {
        padding-left: 10px;

        .back-arrow-chat {
          transform: scaleX(-1);
          width: 15px;
          cursor: pointer;
          margin-right: 9px;
          height: 15px;
        }
      }

      .button-container {
        padding: 0 10px;
        margin: auto 0;
      }
    }
  }

  .button-container .button-primary {
    font-size: 10px;
    white-space: nowrap;
  }

  .message-input-container {
    padding: 30px 10px 40px 10px;

    > img {
      display: none;
    }

    .icons-wrapper {
      align-items: center;

      img {
        display: block;
        object-fit: contain;
      }

      .icon-with-margin {
        margin-bottom: 5px;
      }
    }

    .message-text-input {
      margin: 0 5px 0 0;
    }
  }
  .modal-box {
    width: 300px;
    left: 15%;
    top: 15%;
    flex-direction: column;
    padding: 0 15px 15px 15px;

    .form-container {
      width: unset;

      .date-and-time-container {
        width: 100%;
        display: flex;
        flex-direction: column;

        .form-text-input-small {
          width: 100%;
        }
      }

      .text-area {
        width: 100%;
      }
    }
  }
}
