.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1300;
  background-color: rgba(0, 0, 0, 0.75);
}

.modal {
  .content {
    div {
      h3 {
        padding: 0 20px 0 20px;
      }
    }
  }

  position: fixed;
  top: 100px;
  left: calc(50% - 600px);
  width: 1200px;
  background-color: white;
  padding: 1rem;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 1300;
  animation: slide-down 300ms ease-out forwards;
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {

  .modal {
    width: calc(100% - 20px);
    left: 0;
    margin: 0 10px 0 10px;
  }

}