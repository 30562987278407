.search-wrapper {
  position: relative;

  .search-icon {
    position: absolute;
    left: 20px;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
  }

  .search-input {
    padding: 15px 20px 15px 50px;
    width: 100%;
    font-size: 14px;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.1);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
}