.modal.confirmation-modal {
  width: 450px;
  left: calc(50% - 225px);
  top: calc(50% - 70px);
  padding: 2rem 1rem;

  .modal-button-container {
    justify-content: center;
  }

  .button-primary {
    background-color: #5048E5;
    color: white;
    font-weight: 700;
    padding: 10px 15px 10px;
    border: 0;
    width: 80px;
  }

  p {
    text-align: center;
  }

}

@media(max-width: 768px) {

  .modal.confirmation-modal {
    width: 250px;
    left: 80px;
    top: calc(50% - 90px);
    padding: 2rem 1rem;
  }

}