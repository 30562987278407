body {
  margin: 0;
  padding: 0;
}

//TODO find cleaner way to handle !important statements
[class*="MuiPaper-root-MuiCard-root"] {
  box-shadow: none !important;
}

[class*="MuiPaper-root"] {
  box-shadow: none !important;
  border-right: none !important;
}

[class*="MuiBox-root"] {
  background: none !important;
}

[class*="MuiTable-root"] {
  background: white;
}

[class*="MuiDataGrid-menu"],
[class*="MuiDataGrid-main"] {
  background: white;
}

[class*="MuiDataGrid-row"]:hover {
  cursor: pointer;
}

[class*="MuiDataGrid-root"] {
  font-weight: 600 !important;
  
  [class*="MuiDataGrid-columnHeaders"] {
    background: #71859c25;
  }
}

[class*="MuiDataGrid-columnHeaderRow"] {
  background: #ebedf1;
}

[class*="MuiDataGrid-columnHeaderTitle"] {
  color: #71859c;
  font-weight: 700 !important;
}

[class*="MuiDataGrid-cell"] {
  white-space: pre-wrap !important;
}

.css-f3jnds-MuiDataGrid-columnHeaders {
  background: #ebedf1;
}

.name-description-wrapper {
  text-overflow: ellipsis !important;
  max-height: 100px;

  .header {
    padding-top: 10px;
    font-weight: 600;
    font-size: 15px;
    color: black;
  }

  .text {
    padding-top: 10px;
    font-size: 14px;
    color: #71859c;
  }
}

.fitness-drawer-logo {
  object-fit: cover;
  width: 80%;
  margin: auto
}

.data-grid-container {
  height: 600px;
  width: 100%;
}

.action-menu-container {
  display: flex;
  flex-direction: row;
  width: 5vw;

  p {
    padding-right: 10px;
    color: #71859c;
    text-decoration: underline;
  }

}

.action-menu-container p:hover, img:hover {
  cursor: pointer;
}


.button-with-stroke {
  background: none;
  border: 2px solid #5048e5;
  color: #5048e5;
  margin: 0 15px 0;
  font-weight: 700;
  padding: 10px 15px 10px;
}

.top-button-primary {
  position: absolute !important;
  right: 0;
  background-color: #5048e5 !important;
  color: white !important;
  font-weight: 700 !important;
  padding: 10px 15px 10px !important;
}

.table-image {
  img {
    width: 10vw;
    height: 90px;
    object-fit: cover;
    padding-right: 20px;
  }

  img.client {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50px;
    padding: 0;
  }
}

img.cover-image {
  height: 400px;
  object-fit: contain;
}

.text-area {
  min-height: 200px;
  padding: 15px;
  width: 100%;
  margin-bottom: 20px;
  border-radius: 10px;
  border-color: rgba(0, 0, 0, 0.2);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 16px;
  color: #212121;

  &:focus {
    outline-color: #1976D2;
  }
}

@media (max-width: 768px) {
  .fitness-drawer-undocked {
    position: absolute;
    height: 100%;
  }

  .fitness-drawer-docked {
    position: relative;
    height: auto;
  }

  .top-button-primary {
    padding: 10px !important;
    font-size: 12px !important;
  }

  .autocomplete-box {
    width: unset !important;

    label {
      width: 50vw;
    }

    label.Mui-focused {
      width: auto;
    }

  }

  .data-grid-container {
    overflow: auto;
  }

  [class*="MuiDataGrid-root"] {
    width: 800px;
  }
  [class*="MuiAutocomplete-root"] {
    width: 100% !important;
  }
  .table-image {
    img {
      width: 30vw;
      height: 200px;
      object-fit: contain;
      padding-right: 20px;
    }
  }
}

@media (max-width: 1600px) {
  .action-menu-container {
    flex-direction: column;
    width: 1.3vw;

    p {
      padding: 0;
      margin: 5px 0;
    }
  }
}