.filter-wrapper {
  width: 100%;
  background: white;
  padding: 12px 20px 12px 20px;
  line-height: 0;
  font-size: 14px;
  color: #71859C;
  border-bottom: 1px #71859C25 solid;

  .checked-categories-wrapper {
    display: flex;
    flex-direction: row;

    .checked-category-container {
      padding: 7px 20px 7px 20px;
      background: rgba(0, 0, 0, 0.2);
      margin-right: 10px;

      h2 {
        font-size: 14px;
      }
    }
  }
}

.filter-by-category-container {
  position: relative;
  display: flex;
  flex-direction: row;
  background: white;
  padding: 10px 0 10px 20px;

  .filter-category-container {
    position: relative;
  }

  .filter-option-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 20px;

    .filter-option-value {
      font-size: 14px;
    }

    .filter-category-icon {
      margin-left: 10px;
      transform: rotate(90deg);
    }
  }

  .filter-option-wrapper:hover {
    cursor: pointer;
  }

  .filter-option-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .filter-dropdown {
    position: absolute;
    background: white;
    width: 10vw;
    max-height: 500px;
    top: 50px;
    border: 1px #71859C25 solid;
    z-index: 2;

    h2 {
      font-size: 15px;
      padding: 10px 10px 10px 0;
      margin: 0;
      border-bottom: 1px #71859C25 solid;
    }

    input {
      padding: 10px;
      margin: 15px;
    }

    h2:hover {
      cursor: pointer;
    }
  }
}

@media (max-width: 768px) {
  .filter-by-category-container {
    .filter-category-container {
      .filter-dropdown {
        width: auto;
      }
    }
  }
  .filter-wrapper .checked-categories-wrapper .checked-category-container h2 {
    white-space: nowrap;
  }
}
