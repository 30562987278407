.basic-info-row {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 4vh;
}

.basic-info-container {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-right: 2vw;

  .info-icon {
    width: 55px;
    height: 48px;
    margin-right: 1vw;
  }

  .info-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .info-text-container h2 {
    margin: 0;
  }

  .info-text {
    margin: 0;
    color: #71859C;
    font-weight: 400;
  }
}

.tables-container {
  display: flex;
  justify-content: space-between;

  .clients-table-container {
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    width: 45%;

    .title-container {
      padding: 10px 24px;

      .table-title {
        color: #71859C;
        margin-bottom: 0;
      }

      h3 {
        margin: 0.5vh 0 1vh 0;
        color: #5048E5;
      }
    }

    .data-grid-container {
      height: 600px;
      width: 100%;
      display: flex;
      flex-direction: row;

      .header-hidden {
        display: none;
      }
    }
  }

  .clients-table-container:first-child {
    width: 50%;
  }
}

@media(max-width: 768px) {
  .basic-info-row {
    flex-direction: column;


    .basic-info-container {
      padding: 24px 15px;
      margin-right: 0;

      .info-text-container {
        max-width: 145px;
      }

      .info-icon {
        object-fit: cover;
      }
    }

  }
  .tables-container {
    flex-direction: column;
    overflow: auto;

    .clients-table-container {
      width: 100%;
    }

    .clients-table-container:first-child {
      width: 100%;
    }

    .MuiDataGrid-main {
      width: 800px;
    }

  }

}
