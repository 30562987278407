.add-training-plan-card-container {
  display: flex;
  flex-direction: column;
  width: 66%;
  margin: auto;
  margin-top: 50px;

  .header-container {
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  .table-tab-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: white;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;

    .table-tab-active,
    .table-tab-inactive {
      padding: 10px 25px 10px;
      border-bottom: 3px black solid;
      font-size: 18px;
      margin-left: 15px;
    }

    .table-tab-active:hover,
    .table-tab-inactive:hover {
      cursor: pointer;
    }

    .table-tab-inactive {
      border-bottom: 3px white solid;
    }
  }

  .button-container {
    padding: 20px 0 20px;
    display: flex;
    align-self: flex-end;

    .button-primary {
      background-color: #5048E5;
      color: white;
      font-weight: 700;
      padding: 10px 15px 10px;
    }
  }

  .add-training-plan-name {
    display: flex;
    align-self: flex-start;
    font-size: 20px;
    padding-bottom: 5px;
    margin-left: 10px;
  }

  .add-training-plan-subtitle {
    font-size: 16px;
    padding-left: 20px;
  }

  .card-container {
    display: flex;
    background-color: white;
    border-radius: 15px;
    flex-direction: row;
    box-shadow: 1px 3px 5px #00000020;
    width: 100%;
    justify-content: space-between;
    padding: 20px;
    height: 30%;

    .form-container {
      display: flex;
      flex-direction: column;
      padding: 20px;

      .form-text-input,
      .form-text-input-small, {
        margin-bottom: 20px;
        border-radius: 15px;
        border-color: rgba(0, 0, 0, 0.2);
        width: 30vw;
      }

      .form-text-input-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        max-width: 30vw;
      }

      .form-text-input-small {
        width: 14vw;
      }


    }
  }

  .upload-image-container {
    display: flex;
    flex-direction: column;
    background: white;
    margin: 20px 0 20px 0;

    .cover-image {
      width: 70%;
      object-fit: contain;
      display: flex;
      padding: 20px;
      align-self: center;
    }

    .video-container {
      display: flex;
      justify-content: center;
      align-items: center;

      #video {
        max-width: 23vw;
      }
    }
  }

  .small-form-container {
    display: flex;
    background-color: white;
    border-radius: 15px;
    margin: 20px 0 10px 0;
    flex-direction: row;
    box-shadow: 1px 3px 5px #00000020;
    width: 100%;
    justify-content: space-between;
    padding: 20px;
    height: 30%;

    .file-uploader-container {
      padding: 0 20px;
    }

    .form-text-input-sm-3 {
      margin-top: 20px;
      margin-bottom: 20px;
      border-radius: 15px;
      border-color: rgba(0, 0, 0, 0.2);
      width: 9vw;
    }

    .weight-text-fields-container {
      display: flex;
      flex-direction: row;
      padding: 20px;

      .weight-text-field {
        margin: 0 10px 20px;
        border-radius: 15px;
        border-color: rgba(0, 0, 0, 0.2);
        width: 10vw;
      }
    }
  }

  .training-row-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

@media (max-width: 768px) {
  .add-training-plan-card-container {
    margin-top: 0;
    padding: 0 10px;

    .button-container {
      width: 100%;
      justify-content: center;

      .button-primary {
        width: 100%;
      }
    }

    .training-row-wrapper {
      .form-text-input-sm-3 {
        width: 32%;
      }

      .form-text-input-sm-3:nth-child(2) {
        width: 25%;
      }

      .form-text-input-sm-3:last-child {
        width: 38%;
      }

    }

    width: 100%;

    .card-container {
      flex-direction: column;
      padding: 0;

      .form-container {
        .form-text-input,
        .form-text-input-small {
          width: unset;
        }

        .training-row-wrapper {
          flex-direction: column;
        }
      }
    }

    .small-form-container {
      flex-direction: column;
      padding: 0 15px 15px 15px;
      margin-bottom: 10px;

      .add-training-plan-subtitle {
        padding-left: 0;
      }

      .file-uploader-container {
        width: 100%;
        padding: 0 10px;

        label {
          min-width: unset;
        }
      }
    }
  }

}
