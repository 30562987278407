.sender-message-container,
.receiver-message-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  img.receiver-image,
  img.sender-image {
    width: 50px;
    object-fit: cover;
    height: 50px;
    border-radius: 50px;
    margin-left: 15px;
  }

  .message-container {
    max-width: 45%;

    .delete-message-modal {
      font-size: 12px;
      position: absolute;
      margin-left: -115px;
      margin-top: 5px;
      padding: 5px;
      background-color: #5048E5;
      color: #fff;
      font-weight: 700;
      border-radius: 10px;

      img {
        display: none;
      }

      p {
        margin: 0;
        padding: 0 5px;
      }
    }

    .message-delete-container {
      display: flex;
      align-items: center;
      gap: 10px;
      position: relative;

      .delete-message-dots {
        img {
          width: 1em;
        }
      }

      .message-wrapper {
        img.message-with-image {
          display: flex;
          width: 100%;
          margin: 1vw auto 1vw auto;
        }

        p.date {
          margin: 10px 0 20px 0;
          font-size: 12px;
        }

        .sender-message,
        .receiver-message {
          background: #71859c;
          padding: 5px 30px 5px 30px;
          border-radius: 15px;

          p.text {
            overflow-wrap: anywhere;
            color: white;
            margin: 0;
          }
        }

        .receiver-message {
          background: white;

          p.text {
            overflow-wrap: anywhere;
            color: black;
            margin: 0;
          }
        }
      }
    }
  }
}

.sender-message-container {
  p.date {
    float: right;
    font-size: 0.8em;
    margin: 0.3em 0;
  }
}

.receiver-message-container {
  justify-content: flex-start;

  p.date {
    float: left;
    font-size: 0.8em;
    margin: 0.3em 0;

  }

  img.receiver-image {
    margin-right: 15px;
  }
}

.sender-message-container,
.receiver-message-container:hover {
  cursor: default;
}

@media (max-width: 768px) {
  .sender-message-container .message-container .message-delete-container .message-wrapper .sender-message, .sender-message-container .message-container .message-delete-container .message-wrapper .receiver-message, .receiver-message-container .message-container .message-delete-container .message-wrapper .sender-message, .receiver-message-container .message-container .message-delete-container .message-wrapper .receiver-message {
    padding: 7px 15px;
  }

  .sender-message-container .message-container, .receiver-message-container .message-container {
    max-width: 55%;
  }

  img.receiver-image,
  img.sender-image {
    display: none;
  }

  .sender-message-container .message-container .delete-message-modal {
    margin-left: - 38px;
    padding: 6px 8px;
    border-radius: 50%;

    img {
      display: block;
    }

    p {
      display: none;
    }
  }
}
