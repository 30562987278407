.notifications-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 7vh;
  right: 0;


  .read-all-notifications-container {
    display: flex;
    justify-content: center;;
    align-items: center;
    background: white;
    color: #1D1D1D;
    border: 1px rgba(95, 112, 147, 0.3) solid;
    border-radius: 0 0 15px 15px;
    width: 100%;

    &:hover {
      cursor: pointer;
    }

    .read-all-notifications-text {
      align-self: center;
      padding: 10px 0;
      margin: auto;
      font-size: 16px;
    }
  }

  .notifications,
  .mobileNotifications {
    max-height: 320px;
    overflow-y: auto;
    background-color: white;
    top: 64px;
    z-index: 3;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;

    border: 1px rgba(95, 112, 147, 0.3) solid;
    border-bottom: none;
    right: 0;


    h3 {
      color: #1D1D1D;
    }
  }

  .notifications h3,
  .mobileNotifications h3 {
    padding: 1vw 2vw;
    font-size: 1vw;
    margin: 0;
  }

  .mobileNotifications h3 {
    padding: 6vw 8vw;
    font-size: 4vw;
  }

  .mobileNotifications {
    top: 20vw;
    right: 10vw;
  }
}


@media(max-width: 768px) {
  .notifications {
    max-width: 270px;
  }
  .notifications-container .read-all-notifications-container {
    padding: 10px;

    .read-all-notifications-text {
      font-size: 13px;
    }
  }
}
