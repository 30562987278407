.draggable-component-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.5vw 0 0.5vw;

  .text-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .text {
    color: #71859C;
    padding-left: 10px;
  }

  .icon {
    width: 0.9vw;
  }
}

@media (max-width: 768px) {
  .draggable-component-wrapper {
    .icon {
      width: 20px;
      height: 20px;
    }
  }
}
